<template>
  <p>{{message}}</p>
  <div class="otp-inputs">
    <input ref="otp_1" type="number" @input="otpInput($event, 1)" @keydown="otpInput_backspace($event, 1)" @paste="otpInput_paste"/>
    <input ref="otp_2" type="number" @input="otpInput($event, 2)" @keydown="otpInput_backspace($event, 2)" @paste="otpInput_paste"/>
    <input ref="otp_3" type="number" @input="otpInput($event, 3)" @keydown="otpInput_backspace($event, 3)" @paste="otpInput_paste"/>
    <input ref="otp_4" type="number" @input="otpInput($event, 4)" @keydown="otpInput_backspace($event, 4)" @paste="otpInput_paste"/>
    <input ref="otp_5" type="number" @input="otpInput($event, 5)" @keydown="otpInput_backspace($event, 5)" @paste="otpInput_paste"/>
    <input ref="otp_6" type="number" @input="otpInput($event, 6)" @keydown="otpInput_backspace($event, 6)" @paste="otpInput_paste"/>
  </div>
</template>

<script>
// 123123
export default {
  name: "OTPInput",
  props: {
    message: String,
    'otpLength': {
      default: 6,
      type: Number,
    }
  },
  computed: {},
  data() {
    return {
      password: "",
    };
  },
  emits: ["opt_change"],
  methods: {
    otpInput_set_otp(ref) {
      if (this.$refs["otp_" + ref].value) {
        this.$refs["otp_" + ref].setAttribute("class", "has-otp");
      } else {
        this.$refs["otp_" + ref].removeAttribute("class");
      }
      let opt_value = "";
      for (let i = 1; i <= this.otpLength; i++) {
        opt_value += this.$refs["otp_" + i].value;
      }
      if (opt_value.length === this.otpLength) {
        this.password = opt_value;
      } else {
        this.password = "";
      }
      this.$emit("opt_change", this.password);
    },
    otpInput_paste(e) {
      let clipboardData;
      let pastedData;
      e.stopPropagation();
      e.preventDefault();
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData("Text");
      if (pastedData && pastedData.trim().length > 0) {
        pastedData = pastedData.trim();
        let pastedData_split = pastedData.split("");
        for (let i = 1; i <= this.otpLength; i++) {
          this.$refs["otp_" + i].blur();
          if ( pastedData_split[i - 1]) {
            this.$refs["otp_" + i].value = pastedData_split[i - 1];
          }
          if (this.$refs["otp_" + i].value) {
            this.$refs["otp_" + i].setAttribute("class", "has-otp");
          } else {
            this.$refs["otp_" + i].removeAttribute("class");
          }
        }
        this.password = pastedData;
        this.$emit("opt_change", this.password);
      }
    },
    otpInput_backspace(event, ref) {
      if (event.key === "Backspace" || event.key === "Delete") {
        if (ref > 1) {
          this.$refs["otp_" + ref].value = "";
          setTimeout(() => {
            this.$refs["otp_" + (ref - 1)].focus();
            this.otpInput_set_otp(ref);
          }, 30);
        }
      }
    },
    otpInput(event, ref) {
      // test 234678
      event.preventDefault();
      let el = event.target;
      let input_val = this.$refs["otp_" + ref].value + "";
      let new_val = input_val.slice(-1);
      el.value = new_val;
      let val = Number(el.value);
      if (isNaN(val)) {
        el.value = "";
      }
      if (this.$refs["otp_" + ref].value !== "") {
        if (this.$refs["otp_" + (ref + 1)]) {
          this.$refs["otp_" + (ref + 1)].focus();
        } else {
          for (let i = 1; i <= this.otpLength; i++) {
            this.$refs["otp_" + i].blur();
          }
        }
      }
      this.otpInput_set_otp(ref);
    }
  },
  updated() {},
};
</script>

<style scoped lang="scss">
.otp-inputs {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  padding: 0 0 1rem;
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    flex: 1;
    width: 20px;
    line-height: 1rem;
    padding: 0.95rem 0.05rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    text-align: center;
    box-sizing: border-box;
    font-weight: bold;
  }

  input.has-otp {
    border: 1px solid #aaa;
  }
}
</style>
