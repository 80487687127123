import { createApp } from 'vue';
import App from './login.vue';
import '../../registerServiceWorker';
import store from '../../store';

import mitt from 'mitt';
const emitter = mitt();
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';


import { VueCookieNext } from 'vue-cookie-next';
let app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(PrimeVue);

app.component('Dialog', Dialog);
app.use(VueCookieNext);
app.use(store);
app.mount('#app');

